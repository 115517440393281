import type { Distribution, DistributionSummary, Invalidation, InvalidationSummary } from '@aws-sdk/client-cloudfront';
import { createApi } from '@mechhive/api';
import type { ApiAwsCreateInvalidationDto } from './dto/aws/ApiAwsCreateInvalidationDto';

export const createInternalApi = () => {
  const api = createApi(  
    {
      name: 'internal',
      defaultHeaders: {
        'accept': 'application/json',
        'content-type': 'application/json'
      }
    }
  );

  return {
    checkUpdate( commitSha: string ) {
      return api.get<{
        newUpdateAvailable: boolean;
      }>( `/api/checkupdate?v=${commitSha}` );
    },
    updateTheme( theme: string ) {
      return api.post( '/api/theme', {
        data: {
          theme
        }
      } )
    },
    aws: {
      cloudfront: {
        distributions: {
          getAll() {
            return api.get<Array<DistributionSummary>>( '/api/aws/cloudfront/distributions' );
          },
          get( distributionId: string ) {
            return api.get<Distribution>( `/api/aws/cloudfront/distributions/${distributionId}` );
          },
          invalidations: {
            getAll( distributionId: string ) {
              return api.get<Array<InvalidationSummary>>( `/api/aws/cloudfront/distributions/${distributionId}/invalidations` );
            },
            get( distributionId: string, invalidationId: string ) {
              return api.get<Invalidation>( `/api/aws/cloudfront/distributions/${distributionId}/invalidations/${invalidationId}` );
            },
            create( distributionId: string, data: ApiAwsCreateInvalidationDto ) {
              return api.post<Invalidation>( `/api/aws/cloudfront/distributions/${distributionId}/invalidations`, {
                data
              } );
            }
          }
        }
      }
    },
    skine: {
      support: {
        securityModule: {
          getSecurityModulesSlug() {
            return api.get<Array<string>>( '/api/skine/support/securitymodule/slugs' )
          }
        }
      }
    }
  }
}
